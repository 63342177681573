<template>
  <v-container>
    <v-row
      class="mb-7 fade-transition"
      justify="center"
      no-gutters
    >
      <v-col
        cols="12"
        sm="11"
      >
        <base-material-card
          icon="mdi-shield-account"
          class="pa-3 text-end"
          color="secondary"
          :loading="loading"
        >
          <v-btn
            color="indigo"
            dark
            @click="openForm()"
          >
            Novo Cliente
          </v-btn>
          <v-row
            no-gutters
            align="center"
            justify="center"
          >
            <v-text-field
              v-model="searchText"
              clearable
              class="mx-2"
              counter="60"
              label="Buscar"
            />

            <!-- UPDATE BTN -->
            <v-btn
              text
              color="secondary"
              @click="updateCustomers()"
            >
              Atualizar
              <v-divider
                class="mx-4"
                vertical
              />
              <v-icon>mdi-update</v-icon>
            </v-btn>
          </v-row>

          <!-- DATA TABLE -->
          <v-data-table
            :items="customers"
            :headers="headers"
            :search="searchText"
            :page.sync="externalPage"
            :items-per-page="perPage"
            :expanded.sync="expanded"
            hide-default-footer
            hide-details
            item-key="name"
            class="text-center"
          >
            <template v-slot:item="{ item, expand, isExpanded }">
              <tr>
                <td>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        :color="item.isOnline ? getStatusColor(item): 'accent'"
                        v-on="on"
                        @click="expand(!isExpanded)"
                      >
                        <v-icon>
                          mdi-information
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ item.isOnline }}</span>
                  </v-tooltip>
                </td>
                <td
                  class="d-block d-sm-table-cell"
                >
                  <p class="text-left align-center mt-3 mb-3">
                    <b>{{ item.code }}</b>
                  </p>
                </td>
                <td
                  class="d-block d-sm-table-cell"
                >
                  <p class="text-left align-center mt-3 mb-3">
                    <b>{{ item.name.toUpperCase() }}</b>
                  </p>
                </td>
                <td
                  class="d-block d-sm-table-cell"
                >
                  <p class="text-left align-center mt-3 mb-3">
                    <b>{{ item.version }}</b>
                  </p>
                </td>
                <td
                  class="d-block d-sm-table-cell"
                >
                  <p class="text-left align-center mt-3 mb-3">
                    <b>{{ item.runMode }}</b>
                  </p>
                </td>
                <td>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list
                      link
                      class="py-0"
                    >
                      <v-list-item
                        @click="openForm(item)"
                      >
                        <v-list-item-title>Editar</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="openForm(item, true)"
                      >
                        <v-list-item-title>Duplicar</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        :disabled="!item.tentacle.bucketAWS || item.tentacle.bucketAWS === ''"
                        :to="`/exec?id=${item._id}`"
                      >
                        <v-list-item-title>Executáveis</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="openStatusPage(item)"
                      >
                        <v-list-item-title>Verificar Status MeuClinic</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
            <template v-slot:expanded-item="{ item }">
              <td
                :colspan="headers.length"
                class="justify-center mt-3 mb-3"
              >
                <v-row
                  justify="center"
                  no-gutters
                >
                  <v-col
                    class="text-start align-center mt-3 mb-3"
                    cols="12"
                    md="11"
                  >
                    <!-- eslint-disable vue/singleline-html-element-content-newline -->
                    <h2>Informação Geral</h2><br>
                    ID:  {{ customerInfo[item.name]._id }}<br>
                    <div v-if="customerInfo[item.name].corporateName">Corporação:  {{ customerInfo[item.name].corporateName }}</div>
                    <div v-if="customerInfo[item.name].ie">IE:  {{ customerInfo[item.name].ie }}</div>
                    <div v-if="customerInfo[item.name].im">IM:  {{ customerInfo[item.name].im }}</div>
                    <div v-if="customerInfo[item.name].cnpj">CNPJ:  {{ customerInfo[item.name].cnpj }}</div>
                    Telefone:  {{ formatPhoneNumber(customerInfo[item.name].phoneNumber) }}<br>
                    Endereço:  {{ customerInfo[item.name].address }}, {{ customerInfo[item.name].city }}, {{ customerInfo[item.name].state }}<br>
                    CEP:  {{ formatCep(customerInfo[item.name].cep) }}<br>
                    <div class="my-5" />

                    <h3>Contatos</h3><br>
                    <div
                      v-for="(object, index) in customerInfo[item.name].contacts"
                      :key="index"
                    >
                      <div v-if="object.name">Nome:  {{ object.name }}</div>
                      <div v-if="object.email">E-mail:  {{ object.email }}</div>
                      <div v-if="object.mobile">Celular:  {{ formatPhoneNumber(object.mobile) }}</div>
                      <div v-if="object.phoneNumber">Telefone:  {{ formatPhoneNumber(object.phoneNumber) }}</div>
                    </div>
                    <div class="my-5" />

                    <h3>Serviços</h3><br>
                    <h4>
                      Agendamento Online:
                      {{
                        enabledChecker(customerInfo[item.name]
                          && customerInfo[item.name].services
                          && customerInfo[item.name].services.scheduleOnline
                          && customerInfo[item.name].services.scheduleOnline.enabled)
                      }}
                    </h4>
                    <div
                      v-if="customerInfo[item.name]
                        && customerInfo[item.name].services
                        && customerInfo[item.name].services.scheduleOnline
                        && customerInfo[item.name].services.scheduleOnline.enabled
                      "
                    >
                      Cor: {{ customerInfo[item.name].services.scheduleOnline.colorHTML }}<br>
                      Classe CSS: {{ customerInfo[item.name].services.scheduleOnline.cssClass }}<br>
                      Logo: {{ customerInfo[item.name].services.scheduleOnline.logo }}<br>
                      Tema: {{ customerInfo[item.name].services.scheduleOnline.theme }}<br>
                      Criado em  {{ moment(customerInfo[item.name].services.scheduleOnline.createdAt).format('LLL') }}<br>
                      Última atualização:  {{ moment(customerInfo[item.name].services.scheduleOnline.updateAt).format('LLL') }}<br>
                    </div>
                    <div class="my-5" />

                    <h4>
                      MeuClinic:
                      {{
                        enabledChecker(customerInfo[item.name]
                          && customerInfo[item.name].services
                          && customerInfo[item.name].services.myClinic
                          && customerInfo[item.name].services.myClinic.enabled)
                      }}
                    </h4>
                    <div
                      v-if="customerInfo[item.name]
                        && customerInfo[item.name].services
                        && customerInfo[item.name].services.myClinic
                        && customerInfo[item.name].services.myClinic.enabled
                      "
                    >
                      Cor: {{ customerInfo[item.name].services.myClinic.colorHTML }}<br>
                      Limite de Usuários: {{ customerInfo[item.name].services.myClinic.limitUser }}<br>
                      Logo: {{ customerInfo[item.name].services.myClinic.logo }}<br>
                      Tema: {{ customerInfo[item.name].services.myClinic.theme }}<br>
                      Criado em  {{ moment(customerInfo[item.name].services.myClinic.createdAt).format('LLL') }}<br>
                      Última atualização:  {{ moment(customerInfo[item.name].services.myClinic.updateAt).format('LLL') }}<br>
                    </div>
                    <div class="my-5" />

                    <h4>
                      API-Risc:
                      {{
                        enabledChecker(customerInfo[item.name]
                          && customerInfo[item.name].services
                          && customerInfo[item.name].services.apiRisc
                          && customerInfo[item.name].services.apiRisc.enabled)
                      }}
                    </h4>
                    <div
                      v-if="customerInfo[item.name]
                        && customerInfo[item.name].services
                        && customerInfo[item.name].services.apiRisc
                        && customerInfo[item.name].services.apiRisc.enabled
                      "
                    >
                      Criado em  {{ moment(customerInfo[item.name].services.apiRisc.createdAt).format('LLL') }}<br>
                      Última atualização:  {{ moment(customerInfo[item.name].services.apiRisc.updateAt).format('LLL') }}<br>
                    </div>
                    <div class="my-5" />

                    <h4>
                      VirtualClin:
                      {{
                        enabledChecker(customerInfo[item.name]
                          && customerInfo[item.name].services
                          && customerInfo[item.name].services.virtualClin
                          && customerInfo[item.name].services.virtualClin.enabled)
                      }}
                    </h4>
                    <div
                      v-if="customerInfo[item.name]
                        && customerInfo[item.name].services
                        && customerInfo[item.name].services.virtualClin
                        && customerInfo[item.name].services.virtualClin.enabled
                      "
                    >
                      Criado em  {{ moment(customerInfo[item.name].services.virtualClin.createdAt).format('LLL') }}<br>
                      Última atualização:  {{ moment(customerInfo[item.name].services.virtualClin.updateAt).format('LLL') }}<br>
                    </div>
                    <div class="my-5" />

                    <h4>
                      Doctoralia:
                      {{
                        enabledChecker(customerInfo[item.name]
                          && customerInfo[item.name].services
                          && customerInfo[item.name].services.doctoralia
                          && customerInfo[item.name].services.doctoralia.enabled)
                      }}
                    </h4>
                    <div
                      v-if="customerInfo[item.name]
                        && customerInfo[item.name].services
                        && customerInfo[item.name].services.doctoralia
                        && customerInfo[item.name].services.doctoralia.enabled
                      "
                    >
                      Criado em  {{ moment(customerInfo[item.name].services.doctoralia.createdAt).format('LLL') }}<br>
                      Última atualização:  {{ moment(customerInfo[item.name].services.doctoralia.updateAt).format('LLL') }}<br>
                    </div>
                    <div class="my-5" />

                    <h4>
                      ChatBot:
                      {{
                        enabledChecker(customerInfo[item.name]
                          && customerInfo[item.name].services
                          && customerInfo[item.name].services.chatBot
                          && customerInfo[item.name].services.chatBot.enabled)
                      }}
                    </h4>
                    <div
                      v-if="customerInfo[item.name]
                        && customerInfo[item.name].services
                        && customerInfo[item.name].services.chatBot
                        && customerInfo[item.name].services.chatBot.enabled
                      "
                    >
                      Criado em  {{ moment(customerInfo[item.name].services.chatBot.createdAt).format('LLL') }}<br>
                      Última atualização:  {{ moment(customerInfo[item.name].services.chatBot.updateAt).format('LLL') }}<br>
                    </div>
                    <div class="my-5" />

                    <h4>
                      DocPlanner:
                      {{
                        enabledChecker(customerInfo[item.name]
                          && customerInfo[item.name].services
                          && customerInfo[item.name].services.docPlanner
                          && customerInfo[item.name].services.docPlanner.enabled)
                      }}
                    </h4>
                    <div
                      v-if="customerInfo[item.name]
                        && customerInfo[item.name].services
                        && customerInfo[item.name].services.docPlanner
                        && customerInfo[item.name].services.docPlanner.enabled
                      "
                    >
                      Criado em  {{ moment(customerInfo[item.name].services.docPlanner.createdAt).format('LLL') }}<br>
                      Última atualização:  {{ moment(customerInfo[item.name].services.docPlanner.updateAt).format('LLL') }}<br>
                    </div>
                    <div class="my-5" />

                    <h4>
                      WebCheckin:
                      {{
                        enabledChecker(customerInfo[item.name]
                          && customerInfo[item.name].services
                          && customerInfo[item.name].services.webCheckin
                          && customerInfo[item.name].services.webCheckin.enabled)
                      }}
                    </h4><br>
                    <div
                      v-if="customerInfo[item.name]
                        && customerInfo[item.name].services
                        && customerInfo[item.name].services.webCheckin
                        && customerInfo[item.name].services.webCheckin.enabled
                      "
                    >
                      Criado em  {{ moment(customerInfo[item.name].services.webCheckin.createdAt).format('LLL') }}<br>
                      Última atualização:  {{ moment(customerInfo[item.name].services.webCheckin.updateAt).format('LLL') }}<br>
                    </div>
                    <div class="my-5" />

                    <h4>Tentacle version: {{ customerInfo[item.name].version }}</h4>
                    <h4>Tentacle mode: {{ customerInfo[item.name].runMode }}</h4>

                    <br>
                    <h4>Bucket AWS: {{ customerInfo[item.name].tentacle.bucketAWS }}</h4>

                    <br>
                    <div>
                      Domínio:
                      <a
                        :href="customerInfo[item.name].domain"
                        target="_blank"
                      >{{ customerInfo[item.name].domain }}</a>
                    </div>
                    <div class="my-5" />

                    <div v-if="customerInfo[item.name].tunnelConfig && customerInfo[item.name].tunnelConfig.url">
                      Subdomínio:  {{ customerInfo[item.name].tunnelConfig.url }}
                    </div>
                    <v-divider
                      v-if="customerInfo[item.name].tunnelConfig && customerInfo[item.name].tunnelConfig.url"
                      class="mb-2"
                    />

                    <div v-if="customerInfo[item.name].createdAt">
                      Criado em  {{ moment(customerInfo[item.name].createdAt).format('LLL') }}
                    </div>
                    Última atualização:  {{ moment(customerInfo[item.name].updateAt).format('LLL') }}<br>

                  <!-- eslint-enable vue/singleline-html-element-content-newline -->
                  </v-col>
                </v-row>
                <div class="my-5" />
              </td>
            </template>
          </v-data-table>

          <v-row
            align="center"
            justify="center"
            class=""
          >
            <v-col cols="10">
              <v-pagination
                v-model="externalPage"
                :length="pageCount"
                circle
                total-visible="7"
                color="secondary"
              />
            </v-col>
            <v-col cols="1">
              <v-text-field
                v-model="perPage"
                solo
                type="tel"
                min="-1"
                max="15"
              />
            </v-col>
          </v-row>

          <form-customer
            ref="customerForm"
            @update="updateCustomers()"
          />
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import moment from 'moment'
  import jwt from 'jsonwebtoken'

  export default {
    name: 'ListCustomers',

    components: {
      FormCustomer: () => import('./components/form/CustomerForm'),
    },
    data () {
      return {
        searchText: '',
        customers: [],
        expanded: [],
        externalPage: 1,
        itemsPerPageExternal: 15,

        loading: false,

        customerFormDialog: false,

        moment: moment,

        headers: [
          { text: 'status', value: 'isOnline', align: 'center' },
          { text: '#', value: 'code' },
          { text: 'Nome', value: 'name' },
          { text: 'Versão', value: 'version' },
          { text: 'Modo', value: 'runMode' },
          { text: 'id', value: '_id', align: ' d-none' },
          { text: 'domain', value: 'domain', align: ' d-none' },
          { text: 'corporateName', value: 'corporateName', align: ' d-none' },
          { text: 'Ações', value: 'actions', align: 'center', sortable: false },
        ],
      }
    },
    computed: {
      ...mapGetters('customers', ['customerList']),
      pageCount () {
        return Math.ceil(this.customers.length / (this.itemsPerPageExternal || 1))
      },
      perPage: {
        get: function () {
          return (this.itemsPerPageExternal || 1)
        },
        set: function (newValue) {
          if (newValue <= 1 || typeof newValue !== 'number' || isNaN(newValue)) {
            this.itemsPerPageExternal = 1
          } else {
            this.itemsPerPageExternal = newValue
          }
        },
      },
      customerInfo () {
        const customers = {}
        this.customerList.map(obj => { customers[obj.name] = obj })
        return customers
      },
    },
    created () {
      this.updateCustomers()
    },
    methods: {
      ...mapActions('customers', ['listCustomers', 'getCustomers']),
      async updateCustomers () {
        this.loading = true
        this.pingCustomers()
        await this.listCustomers()

        this.customers = this.customerList.map(a => ({
          ...a,
          isOnline: a.enabled ? 'Buscando Informações' : 'Desabilitado',
        }))
        this.loading = false
      },
      async pingCustomers () {
        this.loading = true
        const pingStatistics = await this.getCustomers()
        this.customers = this.customerList.map(a => ({
          ...a,
          isOnline: a.enabled ? (pingStatistics.result.on.includes(a.name) ? 'Online' : 'Offline') : 'Desabilitado',
        }))
        this.loading = false
      },
      openForm (item, clone = false) {
        this.$refs.customerForm.openForm(item ? item._id : null, clone)
      },
      openStatusPage (item) {
        const token = jwt.sign({ name: item.name, domain: item.domain }, 'vf50T8t4JoyA47nADZWGy')
        window.open(`${window.location.href.replace('/list-customer', '')}/monitor/${token}`)
      },
      formatPhoneNumber (str) {
        const cleaned = String(str).trim().replace(/\D/g, '')
        const match = cleaned.match(/^(\d{2})(\d{4,5})(\d{4})/)

        if (!match) {
          return ''
        }
        return `(${match[1]}) ${match[2]}-${match[3]}`
      },
      formatCep (str) {
        if (str && str !== '') {
          const cleanStr = String(str).trim()
          return `${cleanStr.slice(0, 5)}-${cleanStr.slice(5)}`
        }
        return 'não registrado'
      },
      enabledChecker (value) {
        return value ? 'Habilitado' : 'Desabilitado'
      },
      getStatusColor (item) {
        switch (item.isOnline) {
          case 'Online':
            return 'success'
          case 'Offline':
            return 'error'
          case 'Buscando Informações':
            return 'accent'
          default:
            return 'secondary'
        }
      },
    },
  }
</script>
